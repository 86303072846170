import React, { Component } from 'react'
import { Link } from 'gatsby'
import { Instagram, Facebook, LinkedIn } from './icons'
import NewsletterForm from './newsletter-form'

import FooterLogo from '../assets/images/logo-footer.svg'
import UpArrow from '../assets/images/up-arrow.svg'

class Footer extends Component {
  render() {
    return (
      <>
        <footer className='footer'>
          <div className='footer__inner'>
            <div className='footer__row'>
              <div className='footer__col'>
                <Link to='/' title='Marchetti Group' className='logo'>
                  <img src={FooterLogo} alt="Marchetti Group" />
                </Link>
              </div>
              <div className='footer__col'>
                <div className='footer__content'>
                  <p>We’re people first and professionals second – which means no polyester suits, bad toupees or smooth-talking real estate nonsense.</p>
                  <p>110/95 Hazel Glen Drive, Doreen.</p>
                </div>
                <div className='footer__subscribe'>
                  <NewsletterForm />
                </div>
              </div>
            </div>
            <div className='footer__row footer__row--blank'>
            </div>
            <div className='footer__row footer__row--last'>
              <div className='footer__col'>
                <p>© Copyright 2022 Marchetti Group. Design <Link to='//lbdstudios.com.au' target='_blank' rel='noopener noreferrer'>LBD STUDIOS</Link>.</p>
              </div>
              <div className='footer__col'>
                <nav className="footer__social">
                  <ul>
                    <li><Link to='https://www.instagram.com/themarchettigroup/' target='_blank' rel='noopener noreferrer' aria-label='Instagram'><Instagram color={'#FFFFFF'} /></Link></li>
                    <li><Link to='https://www.facebook.com/marchettigroup/' target='_blank' rel='noopener noreferrer' aria-label='Facebook'><Facebook color={'#FFFFFF'} /></Link></li>
                     <li><Link to='https://www.linkedin.com/in/christian-marchetti-9a64a3229/' target='_blank' rel='noopener noreferrer' aria-label='LinkedIn'><LinkedIn color={'#FFFFFF'} /></Link></li>
                  </ul>
                </nav>
                <button className='footer__arrow' onClick={() => window.scroll({ top: 0, left: 0, behavior: 'smooth'})}>
                  <img src={UpArrow} alt="Marchetti Group" />
                </button>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  }
}

export default Footer
