import React, { Component } from 'react'
import RightArrow from '../assets/images/right-arrow-submit.svg'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class NewsletterForm extends Component {

  state = {
    email: '',
  }

  _handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
    .then(() => {
      this.setState({result: 'success', msg: ''})
    })
    .catch(error => this.setState({result: 'fail', msg: error}))
  }

  render() {
    let props = {
      ref: 'form',
      name: 'newsletter',
      className: 'form',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

    if (this.state.result === 'success')
      return (<p className='newsletter__success'>Thanks for subscribing!</p>)

    return (
      <form {...props}>
        <div className='form__row form__row--subscribe'>
          <input type='email' name='email' placeholder='Newsletter Subscribe' onChange={this._handleChange} required  />
          <button type='submit'>
            <img src={RightArrow} alt='Subscribe' />
          </button>
        </div>
      </form>
    )
  }
}

export default NewsletterForm