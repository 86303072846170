import React, { Component } from 'react'
import Link from '../utils/link'

import Logo from '../assets/images/logo.svg'
import FooterLogo from '../assets/images/logo-footer.svg'

class Header extends Component {

  state = {
    offCanvas: false,
    headerShow: true,
    direction: 0,
    prevDirection: 0,
    prevScroll: 0,
  }

  componentDidMount() {
    window.addEventListener('scroll', this._checkScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._checkScroll)
  }

  _hideOffCanvas = () => {
    this.setState({ menuActive: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ menuActive: !this.state.menuActive })
    document.querySelector('body').classList.toggle('menu-open')
  }

  _toggleSwitch = () => {
    this.setState({ switchActive: !this.state.switchActive })
    document.querySelector('html').classList.toggle('switch-off')
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _toggleHeader = (direction, curScroll) => {
    if (direction === 2 && curScroll > 120) { 
      this.setState({ headerShow: false })
    }
    else if (direction === 1) {
      this.setState({ headerShow: true })
    }
  }

  _checkScroll = () => {

    let { direction, prevDirection, prevScroll } = this.state 

    let curScroll = window.scrollY

    // Make sure they scroll more than 5px
    if(Math.abs(prevScroll - curScroll) <= 5) return;

    if (curScroll > prevScroll) { 
      //scrolled up
      direction = 2;
    }
    else if (window.scrollY < prevScroll) { 
      //scrolled down
      direction = 1;
    }

    if (direction !== prevDirection) {
      this._toggleHeader(direction, curScroll);
    }
    
    prevScroll = curScroll;

    this.setState({ 
      direction: direction,
      prevDirection: prevDirection,
      prevScroll: prevScroll
    })

  }

  render() {

    let { offCanvas, headerShow } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }
    let headerClass = `header`
    if (!headerShow) headerClass += ' header--hide'

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/' title='Marchetti Group' className='logo' {...props}>
              <img src={Logo} alt='Marchetti Group' />
            </Link>
            <div className='header__nav'>
              <nav className='header__menu'>
                <ul>
                  <li><Link to='/buy' {...props}>Buy</Link></li>
                  <li><Link to='/sold' {...props}>Sold</Link></li>
                  <li><Link to='/lease' {...props}>Lease</Link></li>
                </ul>
              </nav>
              <span className='header__line' />
              <button onClick={this._toggleOffCanvas} className={ this.state.offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
                <span className="lines"></span>
              </button>
            </div>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <Link to='/' title='Marchetti Group' className='off-canvas__logo' {...props}>
              <img src={FooterLogo} alt='Marchetti Group' />
            </Link>
            <div className='off-canvas__nav'>
              <span className='header__line' />
              <button onClick={this._toggleOffCanvas} className={ this.state.offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
                <span className="lines"></span>
              </button>
            </div>
            <nav className='off-canvas__menu'>
              <ul>
                <li><Link to='/' {...props}>Home</Link></li>
                <li><Link to='/buy' {...props}>Buy</Link></li>
                <li><Link to='/sold' {...props}>Sold</Link></li>
                <li><Link to='/lease' {...props}>Lease</Link></li>
                <li><Link to='/about' {...props}>Our Story</Link></li>
                <li><Link to='/team' {...props}>Team</Link></li>
                <li><Link to='/contact' {...props}>Contact</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
